<template>
	<div>
		<b-row>
			<b-colxx xxs="12">
				<h1>PEDIDOS</h1>
			</b-colxx>
				<b-colxx xxs="12" xs="12" v-if="dataArray.length > 0">
				 	<b-card class="mt-4">
							<b-table
					            ref="custom-table"
					            class="vuetable"
					            sort-by="title" sort-desc.sync="false"
					            @row-selected="rowSelected"
					            selectable
					            :select-mode="bootstrapTable.selectMode"
					            :current-page="currentPage"
					            selectedVariant="primary"
					            :fields="bootstrapTable.fields"
					            :items="dataArray"
					            :per-page="perPage"
					          >
					            <template slot="status" slot-scope="data">
					              <b-badge class="mb-1" :variant="data.item.statusColor">{{ data.value }}</b-badge>
					            </template>
				        	</b-table>
					        <b-pagination
					            size="sm"
					            align="center"
					            :total-rows="totalRows"
					            :per-page="perPage"
					            v-model="currentPage"
					          >
					            <template v-slot:next-text>
					              <i class="simple-icon-arrow-right"/>
					            </template>
					            <template v-slot:prev-text>
					              <i class="simple-icon-arrow-left"/>
					            </template>
					            <template v-slot:first-text>
					              <i class="simple-icon-control-start"/>
					            </template>
					            <template v-slot:last-text>
					              <i class="simple-icon-control-end"/>
					            </template>
				          	</b-pagination>
				        </b-card>
			      	</b-colxx>
					<b-colxx xxs="12" xs="12" class="mt-4" v-if="bootstrapTable.selected.length>0">
						<b-card :title="$t('Detalle Pedido')" >
						    <b-colxx xxs="12">
						        
					          <b-form @submit.prevent="onGridFormSubmit">
					            <b-row>

					              	<b-colxx sm="2">
					                	<b-form-group :label="$t('Folio')">
					                  		<b-form-input readonly type="text" v-model="selected.id" />
					                	</b-form-group>
					              	</b-colxx>

					              	<b-colxx sm="4">
					                	<b-form-group :label="$t('Fecha')">
					                  		<b-form-input readonly v-bind:value="formatoFecha(selected.fechaCreacion)"  ></b-form-input>
					                	</b-form-group>
					              	</b-colxx>
					              	<b-colxx sm="4">
					                	<b-form-group :label="$t('ID cliente')">
					                  		<b-form-input readonly v-bind:value="selected.idCliente"  ></b-form-input>
					                	</b-form-group>
					              	</b-colxx>

					              	<b-colxx sm="12">
					              		<b-table :items="selected.detalle"
					              		   :fields="detallePedidoFields">
					              		</b-table>
					              		<p class="txt-right"><b>Total:</b> {{selected.total}}</p>
					              	</b-colxx>
					            
					            </b-row>
					          </b-form>
						    </b-colxx>
	  
				      	</b-card>
					</b-colxx>
		</b-row>
	</div>
</template>
<script>

import { request, mapActions} from 'vuex';
	export default{
		data(){
			return{
				//PEDIDOS TABLE
			    dataArray: [],
			    bootstrapTable: {
			        selected: [],
			        selectMode: 'single',
			        fields: [
			          	{ key: 'id', label: 'Folio', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'idCliente', label: 'Id Cliente', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'fechaCreacion', label: 'Fecha', sortable: true, tdClass: 'text-muted', formatter:  function(valueFecha){
												return new Date(valueFecha).toLocaleString("es-MX");
											}
						},
			          	{ key: 'estado', label: 'Estado', sortable: true, tdClass: 'text-muted' },
			        ]
			    },
			    currentPage: 1,
			    perPage: 5,
			    detallePedidoFields: [

			    	{key: 'descripcion', label:'Descripción'},
			    	{key: 'cantidad', label:'Cantidad'},
			    	{key: 'precio', label:'Precio'},
			    ],
			    
			}
		},
		computed:{
			totalRows(){
				return this.dataArray.length;
			},
			selected(){
				return this.bootstrapTable.selected[0];
			},
		},

		mounted: function(){
			this.getPedidos();
		},
		methods:{
			...mapActions(["getAll","getOne", "create", "update", "delete"]),

			formatoFecha:function(valueFecha){
				return new Date(valueFecha).toLocaleString("es-MX");
			},

		    onPaginationData (paginationData) {
		      this.$refs.pagination.setPaginationData(paginationData)
		    },
		    onChangePage (page) {
		      this.$refs.vuetable.changePage(page)
		    },
		    rowSelected (items) {
		      this.bootstrapTable.selected = items
		    },
		    getPedidos(){

				var token = JSON.parse(localStorage.getItem('user')).token;
		    	var instance = this;

		    	let payload = {
		    		endpoint: "/api/pedido/todos",
		    		token: token
		    	}

		    	this.getAll(payload).then(response => {
		    		instance.dataArray = response.data.data;
		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });
		    },
		}
	}
</script>